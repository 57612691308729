import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { getImage } from "gatsby-plugin-image";

// Components
import Seo from "../../components/SEO";

// Template
import ProductPage from "../../templates/product-page";

// Assets
import CollectIcon from "../../images/icons/dashboard-collect.svg";
import AnalzyeIcon from "../../images/icons/dashboard-analyze.svg";
import DashboardIcon from "../../images/icons/dashboard-pc.svg";
import Plan1Icon from "../../images/icons/product-plans-1.svg";
import Plan2Icon from "../../images/icons/product-plans-2.svg";
import Plan3Icon from "../../images/icons/product-plans-3.svg";

const ProductsPage = ({
  data: { heroSectionImage, featuresSectionImages, relatedBlogs },
}) => {
  const { t } = useTranslation();

  return (
    <ProductPage
      showScheduleSection={false}
      heroSection={{
        title: (
          <Trans
            i18nKey="zealDashboard.heroSection.title"
            values={{
              highlight: t("zealDashboard.heroSection.title.highlight"),
            }}
            components={[<span />]}
          />
        ),
        description: t("zealDashboard.heroSection.description"),
        button: t("zealDashboard.heroSection.button"),
        image: heroSectionImage,
      }}
      featuresSection={{
        title: t("zealDashboard.featuresSection.title"),
        description: t("zealDashboard.featuresSection.description"),
        features: [
          {
            title: t("zealDashboard.featuresSection.powerful.title"),
            description: t(
              "zealDashboard.featuresSection.powerful.description"
            ),
            image: getImage(
              featuresSectionImages.edges[0].node.childImageSharp
            ),
          },
          {
            title: t("zealDashboard.featuresSection.rewards.title"),
            description: t("zealDashboard.featuresSection.rewards.description"),
            image: getImage(
              featuresSectionImages.edges[1].node.childImageSharp
            ),
          },
          {
            title: t("zealDashboard.featuresSection.control.title"),
            description: t("zealDashboard.featuresSection.control.description"),
            image: getImage(
              featuresSectionImages.edges[2].node.childImageSharp
            ),
          },
          {
            title: t("zealDashboard.featuresSection.analytics.title"),
            description: t(
              "zealDashboard.featuresSection.analytics.description"
            ),
            image: getImage(
              featuresSectionImages.edges[3].node.childImageSharp
            ),
          },
        ],
        button: {
          text: t("zealDashboard.featuresSection.button"),
          to: "/contact-us",
        },
      }}
      howItWorksSection={{
        title: t("zealDashboard.howItWorks.title"),
        items: [
          {
            icon: <CollectIcon />,
            title: t("zealDashboard.howItWorks.collect.title"),
            description: t("zealDashboard.howItWorks.collect.description"),
          },
          {
            icon: <AnalzyeIcon />,
            title: t("zealDashboard.howItWorks.analyze.title"),
            description: t("zealDashboard.howItWorks.analyze.description"),
          },
          {
            icon: <DashboardIcon />,
            title: t("zealDashboard.howItWorks.execute.title"),
            description: t("zealDashboard.howItWorks.execute.description"),
          },
        ],
        button: t("zealDashboard.howItWorks.button"),
      }}
      pricingSection={{
        title: t("zealDashboard.pricing.title"),
        description: t("zealDashboard.pricing.description"),
        switcher: {
          text: t("zealDashboard.pricing.switcher.text"),
          yearly: t("zealDashboard.pricing.plans.subtitle.yearly"),
          monthly: t("zealDashboard.pricing.plans.subtitle.monthly"),
        },
        plans: [
          {
            icon: <Plan1Icon />,
            title: t("zealDashboard.pricing.plans1.title"),
            price: {
              monthly: t("zealDashboard.pricing.plans1.price.monthly"),
              yearly: t("zealDashboard.pricing.plans1.price.yearly"),
            },

            features: [
              t("zealDashboard.pricing.plans1.features1"),
              t("zealDashboard.pricing.plans1.features2"),
              t("zealDashboard.pricing.plans1.features3"),
              t("zealDashboard.pricing.plans1.features4"),
              t("zealDashboard.pricing.plans1.features5"),
            ],
          },
          {
            icon: <Plan2Icon />,
            title: t("zealDashboard.pricing.plans2.title"),
            price: {
              monthly: t("zealDashboard.pricing.plans2.price.monthly"),
              yearly: t("zealDashboard.pricing.plans2.price.yearly"),
            },

            features: [
              t("zealDashboard.pricing.plans2.features1"),
              t("zealDashboard.pricing.plans2.features2"),
              t("zealDashboard.pricing.plans2.features3"),
              t("zealDashboard.pricing.plans2.features4"),
              t("zealDashboard.pricing.plans2.features5"),
            ],
          },
          {
            icon: <Plan3Icon />,
            title: t("zealDashboard.pricing.plans3.title"),
            price: {
              monthly: t("zealDashboard.pricing.plans3.price.monthly"),
              yearly: t("zealDashboard.pricing.plans3.price.yearly"),
            },

            features: [
              t("zealDashboard.pricing.plans3.features1"),
              t("zealDashboard.pricing.plans3.features2"),
              t("zealDashboard.pricing.plans3.features3"),
              t("zealDashboard.pricing.plans3.features4"),
              t("zealDashboard.pricing.plans3.features5"),
            ],
          },
        ],
        button: t("zealDashboard.pricing.button"),
      }}
      relatedBlogsSection={{
        title: t("zealDashboard.relatedBlogs.title"),
        description: t("zealDashboard.relatedBlogs.description"),
        blogs: relatedBlogs.nodes,
      }}
      faqSection={{
        title: t("zealDashboard.faq.title"),
        description: t("zealDashboard.faq.description"),
        FAQs: [
          {
            question: t("zealDashboard.faq.question1"),
            answer: t("zealDashboard.faq.answer1"),
          },
          {
            question: t("zealDashboard.faq.question2"),
            answer: t("zealDashboard.faq.answer2"),
          },
          {
            question: t("zealDashboard.faq.question3"),
            answer: t("zealDashboard.faq.answer3"),
          },
          {
            question: t("zealDashboard.faq.question4"),
            answer: t("zealDashboard.faq.answer4"),
          },
        ],
        buttonLink: "/contact-us",
        buttonText: t("zealDashboard.relatedBlogs.button"),
      }}
      yellowSection={{
        title: t("zealDashboard.yellowSection.title"),
        description: t("zealDashboard.yellowSection.description"),
        to: "/contact-us",
        btnText: t("zealDashboard.yellowSection.button"),
      }}
    />
  );
};

export default ProductsPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "zeal-dashboard"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    heroSectionImage: file(name: { regex: "/dashboard-hero/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    featuresSectionImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { regex: "/dashboard-key-feature/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }

    relatedBlogs: allGhostPost(
      filter: {
        slug: {
          in: [
            "5-reasons-why-data-analytics-is-important-to-any-business"
            "transactional-data-101"
            "how-to-predict-your-consumer-behavior"
          ]
        }
      }
    ) {
      nodes {
        title
        feature_image
        url
        excerpt
        created_at(formatString: "MMM YYYY")
        reading_time
      }
    }
  }
`;
export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return (
    <Seo
      isArabic={isArabic}
      title={isArabic ? "Zeal Dashboard" : "Zeal Dashboard"}
    />
  );
};
